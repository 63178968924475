import { isDEVMODE } from '../_globals'


export default class WknHelperGrid {
  constructor() {
    this.init()
  }

  init() {
    // isDEVMODE && console.log('💡 Init Helper Grid 💡')
    this.addEvents()
  }

  addEvents() {
    this.toggleGrid = this.toggleGrid.bind(this)
    window.addEventListener('keydown', this.toggleGrid)
  }

  toggleGrid(e) {
    if (e.code === 'KeyG' && e.altKey) document.body.classList.toggle('show-grid')
  }

  destroy() {
    isDEVMODE && console.log('💡 Destroy Helper Grid 💡')
    window.removeEventListener('keyup', this.toggleGrid)
    document.body.classList.remove('is--showing')
  }
}
